<template>
  <div id="app">
    <keep-alive ref="keepAliveRef" include="HomeView">
      <router-view
    /></keep-alive>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss"></style>
