import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/djdetail',
    name: 'Djdetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Djdetail.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
